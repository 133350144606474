<template>
  <v-row :style="`min-height: ${$vuetify.breakpoint.height * 0.7}px`" class="ma-0">
    <v-col :style="`min-height: ${$vuetify.breakpoint.height * 0.7}px`" cols="12" class="ma-0 pa-0">
      <v-card flat tile width="100%" height="100%">
        <v-card-title class="pl-0 pr-0">
          <v-btn @click="addToEditor" tile dark color="warning" class="ml-0">{{ $t('buttons.load') }}</v-btn>
          <v-btn @click="downloadFromEditor" tile dark color="secondary" class="ml-4">{{ $t('buttons.download') }}</v-btn>
          <v-btn @click="saveImage" tile dark color="primary" class="ml-4">{{ $t('buttons.save') }}</v-btn>
        </v-card-title>
        <v-card-text style="height: 100%" class="elevation-3">
          <tui-image-editor ref="tuiImageEditor" :include-ui="useDefaultUI" :options="options"></tui-image-editor>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog :max-width="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.width / 4 : $vuetify.breakpoint.width / 1.2"
              overlay-color="transparent"
              content-class="rounded-0" v-model="show" hide-overlay>
      <v-card flat :width="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.width / 4 : $vuetify.breakpoint.width / 1.2" tile>
        <v-card-title>
          {{ $t('forms.section.edit.image.name') }}
        </v-card-title>
        <v-card-text>
          <v-text-field type="text"
                        v-model="name"
                        :placeholder="$t('forms.section.edit.image.name')"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="closeSaveNewImage" tile dark color="secondary" class="ml-4">{{ $t('buttons.cancel') }}</v-btn>
          <v-btn @click="saveNewImage" tile dark color="primary" class="ml-4">{{ $t('buttons.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import 'tui-color-picker/dist/tui-color-picker.css'
import 'tui-image-editor/dist/tui-image-editor.css'
import { ImageEditor } from '@toast-ui/vue-image-editor'
import { saveAs } from 'file-saver'
export default {
  name: 'Editor',
  props: {
    src: null,
    id: null
  },
  components: {
    'tui-image-editor': ImageEditor
  },
  data () {
    return {
      useDefaultUI: true,
      isNew: false,
      show: false,
      name: null
    }
  },
  mounted () {
    document.querySelector('.tui-image-editor-header-buttons button:first-of-type')
      .addEventListener('click', (event) => {
        window.saveAs(saveAs)
      })
  },
  methods: {
    addToEditor () {
      this.isNew = true
      document.querySelector('.tui-image-editor-header-buttons div:first-of-type > input').click()
    },
    downloadFromEditor () {
      document.querySelector('.tui-image-editor-download-btn').click()
    },
    saveImage () {
      if (this.isNew) {
        this.show = true
      } else {
        this.$http.patch(`api/v2/auth/images/${this.id ? this.id : window.sessionStorage.getItem('image_id')}`, {
          image: document.querySelector('canvas.lower-canvas').toDataURL('image/jpeg', 1)
        })
          .then(() => {
            window.sessionStorage.setItem('image', document.querySelector('canvas.lower-canvas').toDataURL('image/jpeg', 1))
          })
      }
    },
    saveNewImage () {
      this.$http.post('api/v2/auth/images', {
        image: document.querySelector('canvas.lower-canvas').toDataURL('image/jpeg', 1),
        name: this.name
      })
        .then(() => {
          this.closeSaveNewImage()
          window.sessionStorage.setItem('image', document.querySelector('canvas.lower-canvas').toDataURL('image/jpeg', 1))
        })
    },
    closeSaveNewImage () {
      this.show = false
      this.name = null
    }
  },
  computed: {
    options () {
      return {
        includeUI: {
          loadImage: {
            path: this.src ? this.src : window.sessionStorage.getItem('image'),
            name: 'Image'
          }
        },
        cssMaxWidth: this.$vuetify.breakpoint.width,
        cssMaxHeight: this.$vuetify.breakpoint.height,
        usageStatistics: false
      }
    }
  }
}
</script>

<style scoped>

</style>
